import React from 'react'
import { Link } from 'gatsby'

export const faqs = [
  {
    q: `Do I have to be accredited to run your games?`,
    a: `Yes, you do. We want to make sure you're set up for success: to sell the games to your clients, expertly insert them into your learning or conference programs, and to run the games smoothly. Most importantly, our games deliver customised learning with valuable insights for real world application, our accreditation programs will ensure you are able to get the most out of the varied debrief options. If you don’t want to get accredited, if you just want to play a game for a one-off event, we can connect you with our expert facilitation network to guide the experience.`
  },
  {
    q: `How do I know which game is right for me?`,
    a: <>Feeling overwhelmed with choice? No sweat! We're here to be your guide. Just tell us a bit about yourself and your event goals (think "team bonding" or "leadership training"), and we'll match you with the perfect game. You can reach our friendly team <Link to="/contact-us">here</Link>!</>
  },
  {
    q: `Can your games be run virtually?`,
    a: <>Global team? No problem! Engage your geographically scattered crew with a virtual experience that'll blow their minds (and socks potentially!). Most of our games are hybrid powerhouses, designed to be played both in-person and in virtual settings. Ready to find the perfect game for your team? <Link to="/contact-us">Contact us</Link> today!</>
  },
  {
    q: `How many participants can be in a game?`,
    a: `Our games are built for flexibility, scaling from intimate gatherings of 6-8 to epic clashes with thousands. Have a unique vision in mind? We're the customisation champions! Let's brainstorm and make your team-building dream a reality.`
  },
  {
    q: `How much does it cost?`,
    a: <>There are a few pricing things to consider depending on your unique circumstance. Visit our <Link to="/pricing">pricing page</Link> for more info or feel free to <Link to="/contact-us">chat to us</Link> directly.</>
  }
]