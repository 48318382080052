import React, { useRef } from 'react'
import { navigate } from 'gatsby'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import { assetUrl } from '../../utilities'
import { games } from '../../games'
import './Showcase.scss'


export const Showcase = ({ game }) => {
  const gamesToShow = Object.keys(games).filter(x => x !== game)
  return (
    <section className="showcase">
      <div className="container">
        {gamesToShow.map((game, i) =>
          <Game
            key={game}
            game={game}
            first={i === 0}
          />
        )}
      </div>
    </section>
  )
}

const Game = ({ game, first }) => {
  const ref = useRef()

  useGSAP(() => {
    gsap.matchMedia().add('(min-width: 1001px)', () => {
      ScrollTrigger.create({
        trigger: ref.current,
        pin: true,
        end: '+=' + (7 * window.innerHeight)
      })
    })
  })

  const item = (
    <div
      className="item"
      style={{ backgroundImage: `url(${assetUrl(`games/${game}/background.png`)})` }}
      ref={ref}
      onClick={() => navigate(`/games/${game}`)}
    >
      <img className="background" src={assetUrl(`games/${game}/logo.png`)} alt="game logo" />
    </div>
  )

  return first
    ? item
    : <div className="diagonal">
        <div>{item}</div>
      </div>
}